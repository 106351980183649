import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'asObservable'
})
export class AsObservablePipe implements PipeTransform {
  transform<T> (x: T | null | undefined | Observable<T | null | undefined>): Observable<T | null | undefined> {
    return x instanceof Observable ? x : of(x);
  }
}