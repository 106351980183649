import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FunctionItem } from '@app/core/navigation/types';
import { delay, distinctUntilChanged, filter, map, Observable, of, shareReplay, Subject, switchMap, take, tap, timer } from 'rxjs';
import { AuthService, HttpService, LoggerService, StorageService } from '@app/core';
import { HubSpotConversationsAPI, HubSpotConversationsSettings } from './interface';
import { environment } from '@env/environment';
import { storageConstants } from '@app/constants';
import { HttpErrorResponse } from '@angular/common/http';
import './interface'

type AuthResponse = {
  token: string;
  email: string;
};

type TokenContent = {
  sub: string;
  aud: string;
  last_name: string;
  exp: number;
  iat: number;
  first_name: string;
};

type HsSettingsOverride = Partial<Omit<HubSpotConversationsSettings, 'loadImmediately' | 'disableInitialInputFocus' | 'identificationToken' | 'identificationEmail'>>;

function getExpirationDate (token: string, marginInMs = 0): Date | undefined {
  try {
    const contents = JSON.parse(atob(token.split('.')[1])) as TokenContent;
    return new Date(contents.exp * 1000 - marginInMs);
  } catch (err) {
    console.warn('Could not parse token', err);
    return undefined;
  }
}

function stringifyError (err: unknown): string {
  if (err instanceof Error) return err.message;
  if (typeof err === 'string') return err;
  return JSON.stringify(err);
}

const storageKey = storageConstants.HUBSPOT_IDENTIFICATION_DETAILS;

const verbose = false;
function log (...args: unknown[]) { if (verbose) console.log(...args); }


/**
 * Communicates with the Hubspot Chat which is initiated by and resides in the app-hubspot-chat component.
 */
@Injectable({
  providedIn: 'root',
})
export class HubspotChatService {
  private readonly _auth    = inject(AuthService);
  private readonly _http    = inject(HttpService);
  private readonly _storage = inject(StorageService);
  private readonly _logger  = inject(LoggerService);

  private readonly _chatVisible = signal(false);
  public readonly chatVisible = this._chatVisible.asReadonly();
  public readonly chatVisible$ = toObservable(this.chatVisible);

  private readonly _hasUnreadMessages = signal(false);
  public readonly hasUnreadMessages = this._hasUnreadMessages.asReadonly();
  public readonly hasUnreadMessages$ = toObservable(this.hasUnreadMessages);

  private readonly _storedAuthDetails$ = this._storage.value$<Partial<AuthResponse>>(storageKey)
    .pipe(
      map(details => {
        log('checking stored auth details', details);
        if ( ! details) return;

        // if an error occurred during the last authentication, try again in a few minutes
        if ( ! details.token) {
          log('stored auth details are invalid, retrying in 10 minutes');
          return { expirationDate: new Date(Date.now() + 10 * 60 * 1000) };
        }

        // check if the token is expired (add 10 minutes margin)
        const expirationDate = getExpirationDate(details.token, 10 * 60 * 1000);
        if ( ! expirationDate || expirationDate.getTime() < Date.now()) {
          log('stored auth details are expired');
          return;
        } else {
          const diffMinutes = (expirationDate.getTime() - Date.now()) / 1000 / 60;
          log(`stored auth details expires in ${ Math.round(diffMinutes * 10) / 10 } minutes`);
        }

        log('stored auth details exists and are up to date');
        return { ...details, expirationDate };
      })
    );

  private readonly _isAuthenticated$ = this._auth.onIsAuthenticated
    .pipe(
      // want no emissions when changing organizations
      distinctUntilChanged(),
    );

  // fetches the auth details, either from the stored details or by authenticating
  private readonly _authDetails$ = this._isAuthenticated$
    .pipe(
      // add random delay to avoid all threads trying to authenticate
      //                          (TODO: accomplish this by using a shared worker service (thread service?):
      //                           -> announce a certain job, the first to announce it will do it and send the result to all others which are waiting for it)
      switchMap(x => x
        ? timer(Math.random() * 3000).pipe(map(() => true))
        : of(false)
      ),
      // check if stored auth details exist and are up to date, otherwise try authenticate
      // (the latter will trigger a re-emission of the "storedAuthDetails" observable)
      switchMap(x => x
        ? this._storedAuthDetails$.pipe(tap(x => x || this._authenticate()))
        : of(undefined)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

  constructor () {

    // perform clean-up logic when the user logs out
    this._isAuthenticated$
    .pipe(
      filter(x => ! x),
      takeUntilDestroyed()
    )
    .subscribe(() => {
      // hide chat
      this.hideChat();

      // remove identification details
      this._storage.remove(storageKey);

      // clear unread messages flag
      this._hasUnreadMessages.set(false);

      // remove widget and clear chat cookies
      window.HubSpotConversations?.clear({ resetWidget: true });
    });

    // renew the authentication details when the token expires
    this._authDetails$
    .pipe(
      filter(Boolean),
      switchMap(x => timer(x.expirationDate.getTime() - Date.now())
        .pipe(
          // add random delay to avoid all threads trying to authenticate (must not be larger than the margin)
          delay(Math.random() * 60 * 1000),
          tap(() => this._authenticate())
        )
      ),
      takeUntilDestroyed()
    )
    .subscribe(() => log('renewed auth details'));

  }

  // authenticate against the hubspot "visitor identification" API
  private _authenticate () {
    // must be logged in
    if ( ! this._auth.isAuthenticated) return;

    log('authenticating against hubspot');
    // request and store auth details
    this._http.get<AuthResponse>('hubspot/authenticate')
    .subscribe({
      next: x => this._storage.set<AuthResponse>(storageKey, x),
      error: (err: unknown) => {
        // ignore 401 errors (unauthorized) when the user's session has expired
        if (err instanceof HttpErrorResponse && err.status === 401) return;

        this._logger.error(`Authentication against HubSpot failed: ${stringifyError(err)}`)
        this._storage.set<Partial<AuthResponse>>(storageKey, { });
      }
    });
  }

  public toggleChatVisibility () { this._chatVisible.update(x => ! x); }
  public showChat () { this._chatVisible.set(true); }
  public hideChat () { this._chatVisible.set(false); }

  public generateNavItem (): FunctionItem {
    return {
      name: 'navigation.shared.item.chat_support',
      icon: 'contact_support',
      position: 'bottom',
      visibleInTopNav: true,
      function: () => this.toggleChatVisibility(),
      badge: this.hasUnreadMessages$.pipe(map(x => x ? '1' : '')),
      badgeClass: of('unread-messages')
    }
  }


  public loadAPI (settingsOverride?: HsSettingsOverride): Observable<HubSpotConversationsAPI | null> {

    // no chat in isolated mode
    if (environment.isolated) return of(null);

    // no chat in cypress mode
    if ('Cypress' in (window as object)) return of(null);

    const api$ = this._authDetails$
      .pipe(
        filter(Boolean),
        tap(() => {
          // attach the script tag to the body if not already present
          if (document.getElementById('hs-script-loader')) return;
          const scriptElem = document.createElement('script');
          scriptElem.type = 'text/javascript';
          scriptElem.id = 'hs-script-loader';
          scriptElem.src = '//js-na1.hs-scripts.com/8832548.js';
          document.body.appendChild(scriptElem);
        }),
        switchMap(x => {
          // apply settings
          const settings: HubSpotConversationsSettings = {
            loadImmediately:          false,
            disableInitialInputFocus: true,   // prevents the focus from being stolen from login form etc.
            identificationToken:      x.token,
            identificationEmail:      x.email
          }
          window.hsConversationsSettings = { ...settings, ...settingsOverride };

          // if the API is already loaded
          if (window.HubSpotConversations) return of(window.HubSpotConversations);

          // await the API to be loaded
          const ready$ = new Subject<HubSpotConversationsAPI>();
          window.hsConversationsOnReady = [() => {
            if ( ! window.HubSpotConversations) {
              this._logger.error('hsConversationsOnReady: HubSpotConversations is not present');
              return;
            }

            ready$.next(window.HubSpotConversations)
          }];
          return ready$.pipe(take(1));
        }),
        tap(api => {
          // listen for unread message count changes
          api.on('unreadConversationCountChanged', ({ unreadCount }) => {
            this._hasUnreadMessages.set(unreadCount > 0);
          });
        })
      );

    return api$;
  }

}
