import { Pipe, PipeTransform } from "@angular/core";
import { NavigationService } from "@app/core";
import { map, Observable } from "rxjs";


@Pipe({
  standalone: true,
  name: 'replaceParameters'
})
export class ReplaceParametersPipe implements PipeTransform {
  constructor (
    private readonly _navigation: NavigationService
  ) {}

  transform(path: string | string[]): Observable<string> {
    // if string, split by '/'
    if (typeof path === 'string') path = path.split('/');

    // replace parameters by their values
    return this._navigation.params$.pipe(map(params => path.map(x => x.startsWith(':') ? params[x.slice(1)] : x).join('/')));
  }
}
